@tailwind base;
@tailwind components;
@import "@fontsource/poppins";
@import "@fontsource/poppins/100.css";
@import "@fontsource/poppins/200.css";
@import "@fontsource/poppins/300.css";
@import "@fontsource/poppins/500.css";
@import "@fontsource/poppins/600.css";
@import "@fontsource/poppins/700.css";
@import "@fontsource/poppins/800.css";
@import "@fontsource/poppins/900.css";
@import "@fontsource/open-sans";
@import "@fontsource/open-sans/300.css";
@import "@fontsource/open-sans/400.css";
@import "@fontsource/open-sans/500.css";
@import "@fontsource/open-sans/600.css";
@import "@fontsource/open-sans/700.css";
@import "@fontsource/open-sans/800.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {
  .landing_hero_bg {
    background: url(./assets/images/landing_hero.png) no-repeat center center;
    background-size: cover !important;
  }

  .student_parent_hero_bg {
    background: url(./assets/images/student_parent_hero_bg.png) no-repeat top center;
    background-size: cover !important;
  }

  .foundation_hero_bg {
    background: url(./assets/images/foundations_logo.png) no-repeat top center;
    background-size: cover !important;
  }

  .college_hero_bg {
    background: url(./assets/images/college_logo.png) no-repeat center center;
    background-size: cover !important;
  }

  .hero_caption {
    /* Frame 1000001824 */
    position: absolute;
    bottom: 0;
    right: 5vw;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .hero_md_caption {
    /* Frame 1000001824 */
    position: absolute;
    bottom: 0;
    right: calc(50vw - 307px);
    background: rgba(0, 0, 0, 0.45);
    border-radius: 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .hero_caption_sm {
    background: rgba(0, 0, 0, 0.45);
    border-radius: 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .border-shadow {
    border-radius: 4px;
    border: 3px solid #3FC6AD;
  }

  .border-grn {
    border-radius: 2px;
    border: 5px solid rgba(100, 100, 100, 0.10);
    background: #FFF;
  }

  .border-grn1 {
    border-radius: 2px;
    border: 2px solid rgba(100, 100, 100, 0.10);
    background: #FFF;
  }

  .text_effect1 {
    text-shadow: 0px 9px 4px rgba(0, 0, 0, 0.25);
  }

  .font-feature1 {
    font-feature-settings: 'clig' off, 'liga' off;
  }

  .contact_logo_bg {
    background: url(./assets/images/contact_logo_bg.svg) 0px -63px no-repeat;
    background-size: auto;

  }

  .btn_shadow {
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  }

  .home_mb_bg {
    background: url(./assets/images/mobile_landing_bg.png) no-repeat;
    background-size: cover !important;
  }

  .top_shadow {
    background: #FFF;
    box-shadow: 0px 5px 6.5px 0px rgba(0, 0, 0, 0.05);
  }

  .input-border {
    @apply border border-b-2 border-t-0 border-l-0 border-r-0 border-[#8D8D8D] max-md:w-full
  }

  .footer_wrap {
    background-size: contain;
  }

  .landing_section_wrap {
    background: #3FC6AD;
    background-size: contain;
    padding: 30px 20px;
  }

  .min-h-wrap {
    min-height: calc(100vh - 391px) !important;
  }

  .header_shadow {
    background: #FFF;
    box-shadow: 0px 5px 6.5px 0px rgba(0, 0, 0, 0.05);
  }

  .player-wrapper {
    /* position: relative; */
    /* border-radius: 5px; */
    height: 100%;
    min-height: 200px;
  }

  .react-player iframe {
    -webkit-transform: rotate(0.000001deg);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .scrollbar_box {
    @apply flex w-1/4 bg-[#D9D9D9] rounded-full h-4 mx-auto relative max-md:hidden
  }

  .scrollbar_box .track {
    @apply cursor-auto h-full rounded-full absolute left-0 w-full
  }

  .scrollbar_box .thumb {
    @apply cursor-auto h-full rounded-full absolute bg-[#3FC6AD]
  }

  .scrollbar_box_blue {
    @apply flex w-1/3 md:w-1/4 bg-[#C9D6E2] rounded-full h-4 mx-auto relative
  }

  .scrollbar_box_blue .track {
    @apply cursor-auto h-full rounded-full absolute left-0 w-full
  }

  .scrollbar_box_blue .thumb {
    @apply cursor-auto h-full rounded-full absolute bg-[#2E90F2]
  }

  .slider_md_wd {
    width: calc(100vw - 48px);
  }
  .contact_bg_decoration {
    background: url(./assets/images/background-sq-1.png) 0 15%  no-repeat, url(./assets/images/background-sq-2.png) 8px 75%  no-repeat;
  }
  .faq_bg_decoration {
    background: url(./assets/images/background-sq-1.png) 0 230px  no-repeat, url(./assets/images/background-sq-2.png) 8px 630px  no-repeat;
  }
  .cost_xl_bg {
    background: url(./assets/images/cost_decoration_bg_1.svg) 0 230px  no-repeat, url(./assets/images/cost_decoration_2.svg) right 280px  
    no-repeat, url(./assets/images/cost_decoration_3.svg) right 230px  
    no-repeat;
  }

}

@tailwind utilities;